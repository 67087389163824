import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// import lib
import { toastAlert } from '../../lib/toastAlert';
import { toFixedDown } from '../../lib/roundOf';
import isEmpty from '../../lib/isEmpty';
import { precentConvetPrice } from "../../lib/calculation"

//import validation
import { WithdrawValidation } from "./validation"

// import api
import { WithdrawReq } from '../../api/walletAction';

const initialFormValue = {
  "receiverAddress": "",
  "amount": 0,
  "otpStatus": false,
  "otp": "",
  'memoAddress': ""
}

const timerInitialValue = {
  isLoading: false,
  type: "send", // send or resend,
  timer: 600, //sec,
  isDisable: false,
  timerStart: false,
};


const Withdraw = (props) => {

  //props
  const { coin } = props


  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux-state
  const walletData = useSelector((state) => state.wallet);
  const currency = useSelector((state) => state.currency);

  // state
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otpTextBox, setOtpTextBox] = useState(false);
  const [formValue, setFormValue] = useState(initialFormValue)
  const [errors, setErrors] = useState({});
  const [userAsset, setUserAssetData] = useState([]);
  const [selectAsset, setSelectAsset] = useState({});
  const [loader, setLoader] = useState(false);
  const { receiverAddress, amount, otpStatus, memoAddress, otp } = formValue;
  const [timerDetail, setTimerDetail] = useState(timerInitialValue);


  //function
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "amount") {
      if (/[^0-9.]/.test(value)) return;
      setFormValue({ ...formValue, ... { [name]: toFixedDown(value, 8) } })
    } else {
      setFormValue({ ...formValue, ... { [name]: value } })
    }
    if (!isEmpty(errors)) {
      setErrors({});
    }
  }

  const handleAsset = () => {
    let tempArr = [...walletData];
    currency?.length > 0 && currency.map((item, index) => {

      let pairIndex = tempArr && tempArr.findIndex((el) => {
        return el._id == item._id;
      })
      if (pairIndex >= 0 && !isEmpty(pairIndex)) {
        tempArr[pairIndex] = {
          ...tempArr[pairIndex],
          ...{
            'image': item.image,
            'minWithdraw': item.minimumWithdraw,
            'maxWithdraw': item.maximumWithdraw,
            'withdrawFee': item.withdrawFee,
            'status': item.status
          }
        }

      }
    })
    setUserAssetData(tempArr)
    let checkIndex = tempArr && tempArr.findIndex((el) => {
      return el._id == coin;
    })
    if (checkIndex >= 0) {
      setSelectAsset(tempArr[checkIndex])
    } else {
      let newArr = tempArr.filter((item) => { return item.status == 'active' })
      setSelectAsset(newArr[0])
    }
  }


  const handleClick = () => {
    let Amount = toFixedDown(selectAsset?.stakeBal - selectAsset?.lockedBal, 8)
    let finalAmount = parseFloat(Amount) - precentConvetPrice(Amount, selectAsset?.withdrawFee);
    let formData = { ...formValue, ...{ ["amount"]: finalAmount } };
    setFormValue(formData);
  }


  const handleSubmit = async () => {
    try {
      setLoader(true);
      let reqData = {
        currencyId: coin,
        minWithdraw: selectAsset?.minWithdraw,
        maxWithdraw: selectAsset?.maxWithdraw,
        receiverAddress,
        memoAddress,
        amount,
        otpStatus,
        otp
      }

      let validationError = WithdrawValidation(reqData);
      if (!isEmpty(validationError)) {
        setErrors(validationError);
        setLoader(false);
        return;
      }

      const { status, loading, message, errors } = await WithdrawReq(reqData, dispatch)
      setLoader(loading);
      if (status === "success") {
        toastAlert('success', message, "WithdrawReq")
        setErrors({})
        setFormValue(initialFormValue)

        navigate("/wallet")
      } else if (status === "OTP") {
        // setMinutes(1);
        // setSeconds(59);
        // setOtpTextBox(true)
        setFormValue({ ...formValue, ...{ otpStatus: true } });
        setErrors({});
        setTimerDetail({
          ...timerDetail,
          ...{
            isLoading: false, isDisable: true, timer: timerDetail.timer - 1,
            timerStart: true,
          },
        });
        toastAlert('success', message, "WithdrawReq")
      } else {
        if (message) return toastAlert('error', message, "WithdrawReq")
        setErrors(errors)
      }
    } catch (err) { }
  }

  // useEffect(() => {
  //   let myInterval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     }
  //     if (seconds === 0) {
  //       if (minutes === 0) {
  //         clearInterval(myInterval);
  //       } else {
  //         setMinutes(minutes - 1);
  //         setSeconds(59);
  //       }
  //     }
  //   }, 1000);
  //   if (otpStatus && seconds === 0 && minutes === 0) {
  //     setFormValue({ ...formValue, ... { 'otpStatus': false } })
  //   }
  //   return () => {
  //     clearInterval(myInterval);
  //   };
  // }, [seconds, minutes]);


  const handleresendotp = async () => {
    try {
      setTimerDetail({
        ...timerDetail,
        ...{ isLoading: true, isDisable: true },
      });
      // let req = { ...formValue, ...{ "otpStatus": false } }

      setLoader(true)
      let reqData = {
        currencyId: coin,
        minWithdraw: selectAsset?.minWithdraw,
        maxWithdraw: selectAsset?.maxWithdraw,
        receiverAddress,
        memoAddress,
        amount,
        otpStatus: false,
        otp
      }

      // let validationError = WithdrawValidation(reqData);
      // if (!isEmpty(validationError)) {
      //   setErrors(validationError);
      //   setLoader(false);
      //   return;
      // }
      const { status, loading, message, error } = await WithdrawReq(reqData, dispatch)
      setLoader(loading)
      if (status == "success") {
        toastAlert('success', message, "WithdrawReq")
        setErrors({})
        setFormValue(initialFormValue)

        navigate("/wallet")
      } else if (status === "OTP") {
        setFormValue({ ...formValue, ...{ otpStatus: true } });
        setErrors({});
        setTimerDetail({
          ...timerDetail,
          ...{
            isLoading: false,
            isDisable: true,
            timer: timerDetail.timer - 1,
            timerStart: true,
            type: "resend",
          },
        });
        toastAlert('success', message, "WithdrawReq")
        localStorage.removeItem("refCode");
      } else {
        setTimerDetail({
          ...timerDetail,
          ...{ isLoading: false, isDisable: false },
        });
        if (error) {
          setErrors(error);
          return false;
        }
        toastAlert('error', message, "WithdrawReq")
      }
    } catch (err) {
      console.log(err, 'error')
    }
  };

  useEffect(() => {
    if (!isEmpty(walletData) && !isEmpty(currency))
      handleAsset()
  }, [walletData, currency])


  useEffect(() => {
    if (timerDetail.timer > 0 && timerDetail.timerStart === true) {
      const intervalId = setInterval(() => {
        setTimerDetail({
          ...timerDetail,
          ...{ timer: timerDetail.timer - 1 },
        });
      }, 2000);

      return () => clearInterval(intervalId);
    } else if (timerDetail.timer === 0 && timerDetail.timerStart === true) {
      setTimerDetail({
        ...timerDetail,
        ...{
          timer: 600,
          timerStart: false,
          isDisable: false,
        },
      });
    }
  }, [timerDetail]);

  // console.log(selectAsset, 'selectAsset')

  return (
    <div className="dash_wrapper">
      <div className="row">
        <div className="col-lg-12">
          <div className="dash_box asset_dashbox_flex asset_dashbox_flex_line">
            <div className="asset_dashbox_flex_left">
              <h3 className="asset_deposit_innertitle">
                Withdraw Terms
              </h3>
              <p>The Minimum Withdrawal amount is: {selectAsset?.minWithdraw} {selectAsset?.coin} </p>
              <ul className="deposit_notes_list">
                <li>
                  Maximum Withdraw : {selectAsset?.maxWithdraw}
                </li>
                <li>
                  Withdraw Fee : {selectAsset?.withdrawFee}%
                </li>
              </ul>
            </div>
            <div className="asset_dashbox_flex_right">
              <h3 className="asset_deposit_innertitle">{selectAsset?.coin} Withdraw</h3>
              <form className="assets_form">
                <div className="mb-3">
                  <label for="withdrawaddress" className="form-label">
                    Receiver Address
                  </label>
                  <input
                    type="text"
                    className="form-control primary_asset_inp"
                    id="receiverAddress"
                    placeholder="Please enter withdrawal address here"
                    name="receiverAddress"
                    value={receiverAddress}
                    onChange={handleChange}
                  />
                  <span className='text-danger'>{errors?.receiverAddress}</span>
                </div>
                {
                  selectAsset.coin === 'XRP' &&
                  <div className="mb-3">
                    <label for="email" className="form-label">
                      Memo Address
                    </label>
                    <input
                      type="text"
                      name="memoAddress"
                      value={memoAddress}
                      onChange={handleChange}
                      className="form-control primary_asset_inp"
                    />
                    {/* <span className='text-danger'>{errors?.memoAddress}</span> */}
                  </div>
                }
                <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <label for="withdrawamount" className="form-label">
                      Withdrawal amount
                    </label>
                    <label className="max_label"
                      onClick={handleClick}
                    >Max</label>
                  </div>
                  <div className="asset_deposit_grp">
                    <input
                      type="number"
                      className="form-control primary_asset_inp"
                      id="amount"
                      name="amount"
                      value={amount}
                      onChange={handleChange}
                      placeholder="Enter Amount"
                    />
                    <span className='text-danger'>{errors?.amount}</span>
                  </div>
                </div>
                {otpStatus == true && (
                  <div class="mb-3">
                    <label for="inputPassword" class="form-label">
                      OTP
                    </label>
                    <div className="asset_deposit_grp">
                      <input
                        type="text"
                        className="form-control primary_asset_inp"
                        id="otp"
                        placeholder="Enter OTP"
                        name="otp"
                        onChange={handleChange}
                        value={otp}
                      />
                      <div className="d-flex align-items-center justify-content-between">
                        {timerDetail.timer !== 0 && timerDetail.timer !== 600 ?
                          <span className="withdrawal_type">
                            {`${timerDetail.timer}Sec`}
                          </span>
                          : <span className="withdrawal_type" onClick={(e) =>
                            !timerDetail.isDisable && handleresendotp()
                          }
                          >Resend Verification Code
                          </span>
                        }
                      </div>
                    </div>
                    <span className="text-danger">{errors?.otp}</span>
                  </div>
                )}
                {/* {
                  (otpStatus === true) && (
                    <div class="mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        {
                          (minutes != 0 || seconds != 0) && otpTextBox ?
                            <p >
                              {" "}
                              OTP valid up to :{" "}
                              {`${minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`}{" "} </p> : 'OTP'
                        }
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        id="otp"
                        placeholder="Enter OTP"
                        name='otp'
                        onChange={handleChange}
                        value={otp}
                      />
                      <span className="text-danger">{errors?.otp}</span>
                    </div>
                  )
                } */}
                <div className="text-center mt-4 mb-4">
                  <button
                    className="primary_btn "
                    type="button"
                    onClick={handleSubmit}
                  >
                    {loader && <i class="fas fa-spinner fa-spin"></i>} Withdraw
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Withdraw;