import isEmpty from "../../lib/isEmpty";
export const WithdrawValidation = value => {
    let errors = {};

    // console.log(value, 'value')

    if (isEmpty(value.receiverAddress)) {
        errors.receiverAddress = "Receiver address field is required"
    }

    if (isEmpty(value.amount)) {
        errors.amount = "Amount field is required"
    } else if (isNaN(value.amount)) {
        errors.amount = "Only allowed numeric"
    } else if (parseFloat(value.minWithdraw) > parseFloat(value.amount)) {
        errors.amount = "Please enter withdraw amount greater than or equal to " + value.minWithdraw
    } else if (parseFloat(value.maxWithdraw) < parseFloat(value.amount)) {
        errors.amount = "Maximum withdraw amount " + value.maxWithdraw
    }


    if (isEmpty(value.otpStatus.toString())) {
        errors.otpStatus = "OTP status is required";
    } else if (![true, false].includes(value.otpStatus)) {
        errors.otpStatus = "Invalid OTP status";
    } else if (value.otpStatus == true && isEmpty(value.otp)) {
        errors.otp = "Please enter a OTP";
    }

    return errors;
}
