import isEmpty from "../../lib/isEmpty";
export const Validation = value => {
    let errors = {};
    if (isEmpty(value.toCurrencyId)) {
        errors.toCurrencyId = "To currency field is required"
    }
    if (isEmpty(value.amount)) {
        errors.amount = "Amount field is required"
    } else if (isNaN(value.amount)) {
        errors.amount = "Only allowed numeric"
    } else if (value.amount <= 0) {
        errors.amount = "Amount must be greater than zero";
    }


    return errors;
}
