import isEmpty from "../../lib/isEmpty";
export const ProfileValidation = value => {
    let errors = {};

    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf)$/;

    if (isEmpty(value.firstName)) {
        errors.firstName = "First name field is required";
    }

    if (isEmpty(value.lastName)) {
        errors.lastName = "Last name field is required";
    }

    if (isEmpty(value.phoneCode)) {
        errors.phoneCode = "Phone number field is required";
    }

    if (isEmpty(value.phoneNo)) {
        errors.phoneCode = "Phone number field is required";
    }

    if (isEmpty(value.address)) {
        errors.address = "Address field is required";
    }

    if (isEmpty(value.country)) {
        errors.country = "Country field is required";
    }

    if (isEmpty(value.city)) {
        errors.city = "City field is required";
    }

    if (isEmpty(value.postalCode)) {
        errors.postalCode = "Postal code field is required";
    }

    // if (value.profile && value.profile.size) {
    //     if (value.profile.size > 1000000) {
    //         errors.profile = "Too large"
    //     } else if (!imageFormat.test(value.profile.name)) {
    //         errors.profile = "Please select valid format"
    //     }
    // } else {
    //     errors.profile = "Image field is required";
    // }

    return errors;
}



export const changePwdValidate = value => {
    let errors = {};
    let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}/g;

    if (isEmpty(value.oldPassword)) {
        errors.oldPassword = "Old password field is required";
    }
    if (isEmpty(value.password)) {
        errors.password = "New password field is required";
    } else if (!passwordRegex.test(value.password)) {
        errors.password = "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18";
    } else if (value.password.length > 18) {
        errors.password = "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18";
    }

    if (isEmpty(value.confirmPassword)) {
        errors.confirmPassword = "Confirm new password field is required";
    } else if (!isEmpty(value.password) && !isEmpty(value.confirmPassword) && value.password != value.confirmPassword) {
        errors.confirmPassword = "New password and confirm new password must be same";
    } else if (value.oldPassword === value.password) {
        errors.password = "Old and new password must be different";
    }

    return errors;
};