import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner";
import Navbar from "../components/Navbar"


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;

}




export default function AccountType(props) {



  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="inner_title_wrapper">
            <h2 className="inner_title">Account Types</h2>
          </div>
          <div className="dash_wrapper">
            <div className="row">
              <div className="col-xl-12">
                <div class="snip1517">
                  <div class="plan">
                    <dov>
                    <header>
                      <h4 class="plan-title">SILVER ACCOUNT</h4>
                      <div class="plan-cost"><span class="plan-price">£ 10,000</span></div>
                    </header>
                    <ul class="plan-features">
                      <li><i class="ion-android-remove"> </i>Minimum Deposit</li>
                      <li><i class="ion-android-remove"> </i>Includes</li>
                      <li><i class="ion-android-remove"> </i>up to 1:100 leverage for forex </li>
                      <li><i class="ion-android-remove"> </i>1:1 leverage for Stocks</li>
                      <li><i class="ion-android-remove"> </i>Cryptocurrencies</li>
                      <li><i class="ion-android-remove"> </i>Commodities</li>
                      <li><i class="ion-android-remove"> </i>Indices</li>
                      <li><i class="ion-android-remove"> </i>Saving Account</li>
                      <li><i class="ion-android-remove"> </i>Daily news</li>
                      <li><i class="ion-android-remove"> </i>Weekly market review</li>
                      <li><i class="ion-android-remove"> </i>Account manager</li>
                      <li><i class="ion-android-remove"> </i>Weekly portfolio progress report</li>
                    </ul>
                    </dov>
                    <div class="plan-select"><a href="#" className="primary_btn">Select Silver</a></div>
                  </div>
                  <div class="plan">
                    <div>
                    <header>
                      <h4 class="plan-title">GOLD ACCOUNT</h4>
                      <div class="plan-cost"><span class="plan-price">£ 50,000</span></div>
                    </header>
                    <ul class="plan-features">
                      <li><i class="ion-android-remove"> </i>Minimum Deposit</li>
                      <li><i class="ion-android-remove"> </i>Includes</li>
                      <li><i class="ion-android-remove"> </i>up to 1:200 leverage for forex</li>
                      <li><i class="ion-android-remove"> </i>1:2 leverage for Stocks</li>
                      <li><i class="ion-android-remove"> </i>Cryptocurrencies</li>
                      <li><i class="ion-android-remove"> </i>Commodities</li>
                      <li><i class="ion-android-remove"> </i>Indices</li>
                      <li><i class="ion-android-remove"> </i>Saving Account</li>
                      <li><i class="ion-android-remove"> </i>Up to 25% margin loan</li>
                      <li><i class="ion-android-remove"> </i>Invitation to special trading events </li>
                      <li><i class="ion-android-remove"> </i>Daily market review</li>
                      <li><i class="ion-android-remove"> </i>Daily portfolio progress report</li>
                      <li><i class="ion-android-remove"> </i>Work with a portfolio manager</li>
                      <li><i class="ion-android-remove"> </i>Monthly session with a certified accountant</li>
                      <li><i class="ion-android-remove"> </i>24/7 account monitoring by top analyst group</li>
                    </ul>
                    </div>
                    <div class="plan-select"><a href="#" className="primary_btn">Select Gold</a></div>
                  </div>
                  <div class="plan">
                    <div>
                    <header>
                      <h4 class="plan-title">
                      PLATINUM ACCOUNT
                      </h4>
                      <div class="plan-cost"><span class="plan-price">£ 100,000</span></div>
                    </header>
                    <ul class="plan-features">
                      <li><i class="ion-android-remove"> </i>Minimum Deposit</li>
                      <li><i class="ion-android-remove"> </i>Includes </li>
                      <li><i class="ion-android-remove"> </i> up to 1:200 leverage for forex</li>
                      <li><i class="ion-android-remove"> </i>1:2 leverage for Stocks</li>
                      <li><i class="ion-android-remove"> </i>Cryptocurrencies</li>
                      <li><i class="ion-android-remove"> </i>Commodities</li>
                      <li><i class="ion-android-remove"> </i>Indices</li>
                      <li><i class="ion-android-remove"> </i>Saving Account</li>
                      <li><i class="ion-android-remove"> </i>Up to 50% margin loan</li>
                      <li><i class="ion-android-remove"> </i>Access to all special trading events</li>
                      <li><i class="ion-android-remove"> </i>Daily market review</li>
                      <li><i class="ion-android-remove"> </i>Daily portfolio progress report</li>
                      <li><i class="ion-android-remove"> </i>Personal portfolio manager</li>
                      <li><i class="ion-android-remove"> </i>Weekly live stream trading webinar</li>
                      <li><i class="ion-android-remove"> </i>Weekly 1 on 1 with market Analyst</li>
                      <li><i class="ion-android-remove"> </i>Monthly session with a certified accountant</li>
                      <li><i class="ion-android-remove"> </i>24/7 account monitoring by top analyst group</li>
                    </ul>
                    </div>
                    <div class="plan-select"><a href="#" className="primary_btn">Select Platinum</a></div>
                  </div>
                  <div class="plan">
                    <div>
                    <header>
                      <h4 class="plan-title">DIAMOND ACCOUNT</h4>
                      <div class="plan-cost"><span class="plan-price">£ 250,000</span></div>
                    </header>
                    <ul class="plan-features">
                      <li><i class="ion-android-remove"> </i>Minimum Deposit</li>
                      <li><i class="ion-android-remove"> </i>Includes</li>
                      <li><i class="ion-android-remove"> </i>up to 1:300 leverage for forex</li>
                      <li><i class="ion-android-remove"> </i>1:4 leverage for Stocks</li>
                      <li><i class="ion-android-remove"> </i>Cryptocurrencies</li>
                      <li><i class="ion-android-remove"> </i>Commodities</li>
                      <li><i class="ion-android-remove"> </i>Indices</li>
                      <li><i class="ion-android-remove"> </i>Saving Account</li>
                      <li><i class="ion-android-remove"> </i>Up to 75% margin loan</li>
                      <li><i class="ion-android-remove"> </i>Access to all special trading events</li>
                      <li><i class="ion-android-remove"> </i>Daily market review</li>
                      <li><i class="ion-android-remove"> </i>Daily portfolio progress report</li>
                      <li><i class="ion-android-remove"> </i>Personal portfolio manager</li>
                      <li><i class="ion-android-remove"> </i>Weekly live stream trading webinar</li>
                      <li><i class="ion-android-remove"> </i>Daily one on one with top Analyst</li>
                      <li><i class="ion-android-remove"> </i>Customized Education</li>
                      <li><i class="ion-android-remove"> </i>Daily market signals</li>
                      <li><i class="ion-android-remove"> </i>In-Depth Research</li>
                      <li><i class="ion-android-remove"> </i>Invites to VIP events</li>
                    </ul>
                    </div>
                    <div class="plan-select"><a href="#" className="primary_btn">Select Diamond</a></div>
                  </div>
                  <div class="plan">
                    <div>
                      <header>
                      <h4 class="plan-title">

                      CORPORATE ACCOUNT
                      </h4>
                      <div class="plan-cost"><span class="plan-price">£ 1,000,000</span></div>
                    </header>
                    <ul class="plan-features">
                      <li><i class="ion-android-remove"> </i>Minimum Deposit</li>
                      <li><i class="ion-android-remove"> </i>Includes</li>
                      <li><i class="ion-android-remove"> </i>up to 1:400 leverage for forex</li>
                      <li><i class="ion-android-remove"> </i>1:5 leverage for Stocks</li>
                      <li><i class="ion-android-remove"> </i>Cryptocurrencies</li>
                      <li><i class="ion-android-remove"> </i>Commodities</li>
                      <li><i class="ion-android-remove"> </i>Indices</li>
                      <li><i class="ion-android-remove"> </i>Saving Account</li>
                      <li><i class="ion-android-remove"> </i>Up to 100% margin loan</li>
                      <li><i class="ion-android-remove"> </i>Access to all special trading events</li>
                      <li><i class="ion-android-remove"> </i>Daily market review & signals</li>
                      <li><i class="ion-android-remove"> </i>Unlimited access to brokers</li>
                      <li><i class="ion-android-remove"> </i>Personal chief portfolio manager</li>
                      <li><i class="ion-android-remove"> </i>Customized Education</li>
                      <li><i class="ion-android-remove"> </i>Daily one on one live stream trading webinar with top Analyst</li>
                      <li><i class="ion-android-remove"> </i>Wealth Manager</li>
                      <li><i class="ion-android-remove"> </i>Invites to VIP events</li>
                    </ul>
                    </div>
                    <div class="plan-select"><a href="#" className="primary_btn">Select Corporate</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
