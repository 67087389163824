import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import browser from "browser-detect";
import Bowser from "bowser";
// import api
import { emailVerify, resetPasswordVerification, loginWithurl, forceLogout, getGeoInfoData } from '../api/users';
import { coinRequestVerify } from '../api/walletAction';

// import lib
import { toastAlert } from '../lib/toastAlert';
import { removeAuthToken, setAuthToken } from '../lib/localStorage';
import { useDispatch, useSelector } from "react-redux";
const EmailVerification = () => {

	const { authToken } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch()
	// const [loginHistory, setLoginHistory] = useState({});
	var loginHistory = {}

	const emailActivation = async () => {
		try {
			const { status, message } = await emailVerify({ userId: authToken });
			toastAlert(status === 'success' ? status : 'error', message, 'emailVerify')
			navigate('/login')
		} catch (err) { }
	}
	const getloginToken = async (data) => {
		try {
			// let token = localStorage.getItem('user_token');
			// if (token) forceLogout(navigate, dispatch)

			await new Promise((resolve) => setTimeout(resolve, 1000));
			console.log("loginHistoryloginHistory", loginHistory)
			const { status, message } = await loginWithurl({ userId: authToken, data : data, loginHistory: loginHistory }, dispatch);
			toastAlert(status === 'success' ? status : 'error', message, 'emailVerify')
			navigate('/wallet')
		} catch (err) {
			console.log("getloginToken_err", err)
		}
	}

	const ResetPassword = async () => {
		try {
			const { status, message } = await resetPasswordVerification({
				authToken: authToken,
			});
			console.log(status, 'responseresponse', message)
			if (status == 'success') {
				toastAlert("success", message, "resetPassword");
				navigate("/reset-password/" + authToken);
			} else {
				toastAlert("error", message, "resetPassword");
				navigate("/login");
			}
		} catch (error) {
			console.log(error, "errro")
		}
	};

	const acceptCoinRequest = async () => {
		try {
			const { status, message } = await coinRequestVerify({ authToken });
			toastAlert(status === 'success' ? status : 'error', message, 'coinRequestVerify')
			navigate('/')
		} catch (err) { }
	}

	useEffect(() => {
		getGeoInfo()
		let pathname = location.pathname;
		if (pathname == '/verification/forgotPassword/' + authToken) {
			ResetPassword()
		}
		if (pathname == '/url-login/' + authToken) {
			let bear = `Bearer ${authToken}`
			// setAuthorization(bear)
			// setAuthToken(bear)
			// navigate('/')
			getloginToken(false)
		}

		if (pathname == '/user-login/' + authToken) {
			let bear = `Bearer ${authToken}`
			// setAuthorization(bear)
			// setAuthToken(bear)
			// navigate('/')
			getloginToken(true)
		}
		// if (pathname == "/email-verification/" + authToken) {
		// 	emailActivation();
		// } else if (pathname == "/withdraw-verification/" + authToken) {
		// 	acceptCoinRequest();
		// }
	}, [])
	const getGeoInfo = async () => {
		try {
			let { result } = await getGeoInfoData();
			const browserResult = browser();
			const Browser = Bowser.getParser(window.navigator.userAgent);

			loginHistory = {
				countryName: result.country_name,
				countryCode: result.country_calling_code,
				ipAddress: result.ip,
				regionName: result.region,
				broswerName: `${Browser.parsedResult.browser.name}${Browser.parsedResult.browser.version}`,
				isMobile: browserResult.mobile,
				os: Browser.parsedResult.os.name
			}

			return true
		} catch (err) {
			console.log("loginHistoryloginHistoryErr", err)
			return true

		}
	};

	return (
		<h3>Loading...</h3>
	)
}

export default EmailVerification;